import { MsalProvider } from '@azure/msal-react';
import { Security } from '@okta/okta-react';
import { FC, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// local import
import { toRelativeUrl } from '@okta/okta-auth-js';
import GlobalSpinner from 'components/GlobalSpinner';
import { msalConfiguration } from 'features/auth/config/azureConfig';
import { oktaConfiguration } from 'features/auth/config/oktaConfig';
import { useAnalytics } from 'hooks/useAnalytics';
import { useNavigate } from 'react-router-dom';
import MainRoute from 'routes';

const App: FC = () => {
  const navigate = useNavigate();
  useAnalytics()
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl('/signin/callback', window.location.origin));
  };

  return (
    <>
      <Suspense fallback={<GlobalSpinner />}>
        <MsalProvider instance={msalConfiguration}>
          <Security oktaAuth={oktaConfiguration} restoreOriginalUri={restoreOriginalUri}>
            <Toaster
              toastOptions={{
                style: { textAlign: 'center', background: '#1e1e1e', color: '#ddff55' },
                success: { duration: 5500 }
              }}
            />
            <MainRoute />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              limit={1}
              pauseOnHover
            />
          </Security>
        </MsalProvider>
      </Suspense>
    </>
  );
};

export default App;
