import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomToolTip from 'components/StylesTooltip';
import React, { FC, memo } from 'react';
import { useAppSelector } from 'store/hooks';
import usePlannedOnboarding from '../../../hooks/usePlannedOnboarding';
import { PlannedInstanceInfo } from '../../../types';
import { PlannedVmDetailsProps } from '../../../types/types';
import InfoBox from '../../infoBox';
import ActionButtons from './ActionButtons';
import TableHeader from './TableHeader';
import Title from './Title';
import VolumeDetails from './volumeDetailsInnerTable';

const tooltipTitle =
  "Since the onboarding of volumes is in progress. Hence can't be rescheduled or cancelled.";

const StyledTableContainer = styled(Box)({
  height: 530,
  overflowY: 'auto'
});

const PlannedVmDetailTable: FC<PlannedVmDetailsProps> = (props) => {
  const { scheduledInstanceIndex } = props;
  const { plannedOnboarding } = useAppSelector((state) => state.unmanagedVm);
  const plannedOnboaring =
    plannedOnboarding?.plannedOnboardingData?.data?.bulkScheduleTriggerResponseList[
      scheduledInstanceIndex
    ];

  const {
    handleOpenInnerTable,
    disableButtonCheck,
    handleUpdateSchedule,
    handleCancelSchedule,
    handleSingleCheck,
    handleAllCheck,
    disableToolTip,
    selectedPlannedSchedule,
    allcheck,
    interminentCheck,
    selectedInstanceId,
    openInnerTableInstancId
  } = usePlannedOnboarding(plannedOnboaring);

  return (
    <>
      <StyledTableContainer>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {selectedPlannedSchedule && <Title selectedPlannedSchedule={selectedPlannedSchedule} />}
          <ActionButtons
            disableButtonCheck={disableButtonCheck}
            handleCancelSchedule={handleCancelSchedule}
            handleUpdateSchedule={handleUpdateSchedule}
          />
        </Box>

        <InfoBox
          content="Rescheduling/cancelling an instance onboarding can't be done after onboarding
            starts for any volume within it."
        />

        <TableContainer
          component={Paper}
          sx={{
            mt: 2,
            border: '1px solid #454546',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '16px'
          }}>
          <Table>
            {selectedPlannedSchedule && (
              <TableHeader
                handleAllCheck={handleAllCheck}
                allcheck={allcheck}
                interminentCheck={interminentCheck}
              />
            )}
            <TableBody>
              {selectedPlannedSchedule &&
                selectedPlannedSchedule?.instanceInfo?.map((instance: PlannedInstanceInfo) => (
                  <React.Fragment key={instance?.instanceId}>
                    <TableRow
                      sx={{
                        '.MuiTableCell-root': {
                          color: 'rgba(255, 255, 255, 0.5)'
                        }
                      }}>
                      <TableCell
                        padding="checkbox"
                        sx={{ position: 'relative' }}
                        data-testid={instance?.instanceId}>
                        <CustomToolTip
                          text={tooltipTitle}
                          showIcon={false}
                          top={12}
                          left={4}
                          disable={disableToolTip(instance)}>
                          <Checkbox
                            checked={selectedInstanceId.includes(instance?.instanceId)}
                            onClick={() => handleSingleCheck(instance)}
                          />
                        </CustomToolTip>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                        {instance?.instanceName}
                      </TableCell>
                      <TableCell align="left">{instance?.osName}</TableCell>
                      <TableCell align="left">{instance?.noOfScheduledVolumes}</TableCell>

                      <TableCell
                        data-testid={instance?.instanceName}
                        align="center"
                        onClick={() => handleOpenInnerTable(instance?.instanceId)}
                        sx={{
                          borderLeft: '1px solid #454546',
                          maxWidth: 50,
                          cursor: 'pointer'
                        }}>
                        <IconButton
                          aria-label="expand row"
                          size="large"
                          sx={{
                            height: 28,
                            width: 28,
                            borderRadius: 99,
                            backgroundColor: 'rgba(221, 255, 85, 0.08)',
                            color: '#DDFF55'
                          }}>
                          {openInnerTableInstancId?.includes(instance?.instanceId) ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse
                          in={openInnerTableInstancId?.includes(instance?.instanceId)}
                          timeout={10}
                          unmountOnExit>
                          <VolumeDetails mountpointInfoList={instance?.mountPointInfos} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableContainer>
    </>
  );
};

export default memo(PlannedVmDetailTable);
