import { useMsal } from '@azure/msal-react';
import { Box, Tooltip, Typography } from '@mui/material';
import Logout from 'assets/images/layout/logout.svg';
import { logout } from 'features/auth/store';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/storage';
import JobsMenu from './jobs';
import config from 'config';

const RightMenu = () => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();

  const handleLogout = async () => {
    dispatch(logout());
    if (storage.getAuthType() === 'azure') {
      const logoutRequest = {
        mainWindowRedirectUri: '/signin',
        postLogoutRedirectUri: '/signin'
      };
      storage.clearCookies();
      localStorage.clear();
      instance.logoutPopup(logoutRequest);
    } else {
      storage.clearAllStorage();
    }
  };

  return (
    <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1.5 }}>
      <JobsMenu />
      <Typography
        variant="h6"
        noWrap
        href="https://sites.google.com/lucidity.cloud/lucidity-knowledgebase/getting-started"
        target="_blank"
        component="a"
        sx={{ fontSize: '16px' }}>
        Documentation
      </Typography>
      <Box className="icon_border" />
      <Box className="tool_icon">
        <Tooltip title="Logout">
          <img src={Logout} alt="logout" onClick={handleLogout} />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default RightMenu;
