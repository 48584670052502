declare const window: Window &
  typeof globalThis & {
    _env_: any;
  };

export const BASE_URL = window?._env_?.REACT_APP_BASE_URL
  ? window._env_.REACT_APP_BASE_URL
  : process.env.REACT_APP_BASE_URL;
export const OktaClientId = window?._env_?.REACT_APP_OKTA_CLIENT_ID
  ? window._env_.REACT_APP_OKTA_CLIENT_ID
  : process.env.REACT_APP_OKTA_CLIENT_ID;
export const OktaIssuer = window?._env_?.REACT_APP_OKTA_ISSUER
  ? window._env_.REACT_APP_OKTA_ISSUER
  : process.env.REACT_APP_OKTA_ISSUER;
export const OktaRedirectUri = window?._env_?.REACT_APP_OKTA_REDIRECT_URI
  ? window._env_.REACT_APP_OKTA_REDIRECT_URI
  : process.env.REACT_APP_OKTA_REDIRECT_URI;
export const OktaDisableHTTPSCheck = window?._env_?.REACT_APP_DISABLE_HTTPS
  ? window._env_.REACT_APP_DISABLE_HTTPS
  : process.env.REACT_APP_DISABLE_HTTPS;
export const OktaIDP = window?._env_?.REACT_APP_OKTA_IDP
  ? window._env_.REACT_APP_OKTA_IDP
  : process.env.REACT_APP_OKTA_IDP;
export const CALLBACK_PATH = '/login/callback';
export const AzureClientID = window?._env_?.REACT_APP_AZURE_CLIENT_ID
  ? window._env_.REACT_APP_AZURE_CLIENT_ID
  : process.env.REACT_APP_AZURE_CLIENT_ID;
export const AzureAuthority = window?._env_?.REACT_APP_AZURE_AUTHORITY
  ? window._env_.REACT_APP_AZURE_AUTHORITY
  : process.env.REACT_APP_AZURE_AUTHORITY;
export const AzureRedirectUri = window?._env_?.REACT_APP_AZURE_REDIRECT_URI
  ? window._env_.REACT_APP_AZURE_REDIRECT_URI
  : process.env.REACT_APP_AZURE_REDIRECT_URI;
export const AzureScope = window?._env_?.REACT_APP_AZURE_SCOPE
  ? window._env_.REACT_APP_AZURE_SCOPE
  : process.env.REACT_APP_AZURE_SCOPE;
export const EnableMicrosoftLogin = window?._env_?.REACT_APP_ENABLE_MICROSOFT_LOGIN
  ? window._env_.REACT_APP_ENABLE_MICROSOFT_LOGIN
  : process.env.REACT_APP_ENABLE_MICROSOFT_LOGIN;
export const azureClientIDAccounts = window?._env_?.REACT_APP_AZURE_CLIENT_ID_ACCOUNTS
  ? window._env_.REACT_APP_AZURE_CLIENT_ID_ACCOUNTS
  : process.env.REACT_APP_AZURE_CLIENT_ID_ACCOUNTS;
export const azureRedirectUriAccounts = window?._env_?.REACT_APP_AZURE_REDIRECT_URI_ACCOUNTS
  ? window._env_.REACT_APP_AZURE_REDIRECT_URI_ACCOUNTS
  : process.env.REACT_APP_AZURE_REDIRECT_URI_ACCOUNTS;
export const gcpClientId = window?._env_?.REACT_APP_GCP_ClIENT_ID
  ? window._env_.REACT_APP_GCP_ClIENT_ID
  : process.env.REACT_APP_GCP_ClIENT_ID;
export const gcpScope = window?._env_?.REACT_APP_GCP_SCOPE
  ? window._env_.REACT_APP_GCP_SCOPE
  : process.env.REACT_APP_GCP_SCOPE;
export const amplitudeKey = window?._env_?.REACT_APP_AMPLITUDE_KEY
  ? window._env_.REACT_APP_AMPLITUDE_KEY
  : process.env.REACT_APP_AMPLITUDE_KEY;
export const enableFeature = window?._env_?.REACT_APP_AMPLITUDE_KEY
  ? window._env_.REACT_APP_ENABLE_FEATURE
  : process.env.REACT_APP_ENABLE_FEATURE;
