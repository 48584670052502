import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { GlobalBarLoader } from 'components/GlobalSpinner';
import { SearchBox, SortBox } from 'components/searchAndSort';
import FilterModal from './FilterModal';
import SortIcon from '@mui/icons-material/Sort';
import { useAppSelector } from 'store/hooks';
import { filterKeys } from 'constants/filter';

const style = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  position: 'relative'
};

const SearchSortUmvm = () => {
  const { unmanagedVmsData, status, filters } = useAppSelector((state) => state.unmanagedVm);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        ...style,
        justifyContent: 'space-between',
        mb: 3
      }}>
      <Box
        sx={{
          ...style
        }}>
        <Typography variant="h2" fontSize={20} lineHeight="28px" sx={{ minWidth: 30, ...style }}>
          {status === 'loading' ? <GlobalBarLoader sx={{ width: '20px' }} /> : unmanagedVmsData?.meta?.totalItems || 0}{' '} Instances
        </Typography>
        <Box
          sx={{
            width: '1px',
            height: '24px',
            background: '#454546'
          }}></Box>
        <Box
          sx={{
            ...style,
            gap: 2,
            alignItems: 'center'
          }}>
          <SearchBox />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1
        }}>
        <Box
          className={'blink_border'}
          sx={{
            color: filters?.length ? 'rgba(221, 255, 85, 1)' : '',
            backgroundColor: filters?.length ? 'rgba(221, 255, 85, 0.2)' : 'rgba(69, 69, 70, 1)',
            px: 2,
            py: 1,
            borderRadius: '12px',
            cursor: 'pointer'
          }}
          onClick={handleFilterBtn}>
          <Box display="flex" gap={1}>
            <SortIcon />
            <Typography color={filters?.length ? 'rgba(221, 255, 85, 1)' : ''}>Filters</Typography>
          </Box>
        </Box>
        <SortBox filterKey={filterKeys.sortUnvm} />
      </Box>
      {
        anchorEl && ( <FilterModal
          handleClose={handleClose}
          anchorEl={anchorEl}
        />)
      }

    </Box>
  );
};

export default SearchSortUmvm;
