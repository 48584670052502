import { FC } from 'react';
import { Grid, Typography, CircularProgress, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Buttons from 'components/buttons';
import { CloudEntityStatusesListType } from 'features/accounts/types';
import { stackSetFaiureReasonUrl } from '../../../utils/constant';

type PropsType = {
  cloudEntity: CloudEntityStatusesListType;
  isOnbardingCompleted: boolean;
  handleSubmit: () => void
};

const SingleAccountTrackProgress: FC<PropsType> = ({ cloudEntity, isOnbardingCompleted, handleSubmit }) => {

  const handleReasonText = (reason: string, category: string) => {
    if (category === 'Role Creation Failed') {
      return (
        <>
          <Typography>
            {reason}{' '}
            <Link
              style={{ color: 'rgb(150, 85, 204)', textDecoration: 'underline', cursor: 'pointer' }}
              target='_blank'
              rel="noopener noreferrer"
              href={stackSetFaiureReasonUrl}>
              Common reasons for StackSet execution failure.
            </Link>
          </Typography>
        </>
      );
    } else return <Typography>{reason}</Typography>;
  };

  return (
    <>
      <Grid
        container
        gap={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        sx={{
          padding: '10px',
          width: '600px',
          height: cloudEntity.status === 'FAILED' ? '360px' : '270px',
          background: 'rgb(33, 35, 36)',
          borderRadius: '8px',
          margin: '50px',
          marginLeft: '180px',
          border: '1px solid rgba(69, 69, 70, 1)',
          alignItems: 'center'
        }}>
        {cloudEntity.status === 'IN_PROGRESS' && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
        {cloudEntity.status === 'SUCCESS' && (
          <Grid item>
            <CheckCircleIcon sx={{ color: 'rgba(88, 224, 67, 1)', fontSize: 45 }} />
          </Grid>
        )}
        {cloudEntity.status === 'FAILED' && (
          <Grid item alignItems="center">
            <WarningIcon sx={{ color: '#E15D5D', fontSize: 45 }} />
          </Grid>
        )}
        <Grid item>
          <Typography fontSize={20} fontWeight={700}>
            {cloudEntity.cloudEntityId}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="rgba(255, 255, 255, 0.5)" fontSize={16} fontWeight={400}>
            {cloudEntity.cloudEntityName}
          </Typography>
        </Grid>
        {cloudEntity.status === 'FAILED' && (
          <Grid item sx={{ p: 2, background: 'rgb(45, 48, 40)', borderRadius: '8px' }}>
            <Grid container gap={2} alignItems={'center'}>
              <Grid item>
                <InfoOutlinedIcon sx={{ color: 'rgba(221, 255, 85, 1)' }} />
              </Grid>
              <Grid item maxWidth={450}>
                <Typography color={'rgba(221, 255, 85, 1)'} fontSize={16} fontWeight={400}>
                  {handleReasonText(cloudEntity.reason, cloudEntity.category)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Buttons
            disabled={!isOnbardingCompleted}
            text="Finish"
            sx={{ width: 100, mr: 2 }}
            btnVarient="contained"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SingleAccountTrackProgress;
