import { Box, MenuItem, Select } from '@mui/material';
import GlobalSpinner from 'components/GlobalSpinner';
import { getTenantPending, logout } from 'features/auth/store';
import { AccountType } from 'features/auth/types';
import { resetTenant } from 'features/filter/store';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import storage from 'utils/storage';
import PartnerLoginInstrumentation from '../../../../../utils/instrumentation';

function AccountList() {
  const partnerLoginAnalytics = new PartnerLoginInstrumentation();
  const dispatch = useAppDispatch();
  const { tenantData, inProcess } = useAppSelector((state) => state.tenants);
  const [defaultAccountId, setDefaultAccountId] = useState<string>('');

  const isAdmin = () => {
    return tenantData?.accountName !== 'admin';
  };

  useEffect(() => {
    const id = storage.getXAccount();
    if (id) {
      setDefaultAccountId(id);
      storage.setInitialAccount({
        initialAccountId: id,
        initialAccountName: tenantData?.accountName
      });
    }
    if (tenantData && isAdmin())
      partnerLoginAnalytics.partnerLogin({ partnerAccount: tenantData.accountName });
  }, []);

  useEffect(() => {
    if (defaultAccountId) {
      dispatch(getTenantPending());
    }
  }, [defaultAccountId]);

  const accountList = tenantData?.accountList;

  const handleAccountName = (e: any) => {
    const { accountId, accountName } = JSON.parse(e.target.value);

    if (accountId === defaultAccountId) return;

    const { initialAccountId, initialAccountName } = storage.getInitialAccount();
    setDefaultAccountId(accountId);
    storage.setXAccount(accountId);
    storage.removeTenentLocalData();
    if (isAdmin() && initialAccountId !== accountId)
      partnerLoginAnalytics.partnerToggleAccounts({
        partnerAccount: initialAccountName,
        customerAccount: accountName
      });
    dispatch(resetTenant());
    dispatch(logout());
  };

  if (inProcess) return <GlobalSpinner height="40px" />;

  if (!accountList || accountList.length <= 1) return null;

  return (
    <Box sx={{ display: 'flex' }}>
      <Select
        data-testid="account-list-select"
        sx={{
          marginLeft: '1.5rem',
          height: '2.5rem',
          borderRadius: '0.8rem'
        }}
        onChange={handleAccountName}
        value={JSON.stringify({
          accountId: defaultAccountId,
          accountName: tenantData?.accountName
        })}
        MenuProps={{
          PaperProps: {
            style: {
              minWidth: '235px',
              borderRadius: '8px',
              border: '1px solid  #454546',
              borderRight: '0px solid  #454546',
              background: 'rgba(22, 23, 24, 0.28)',
              backdropFilter: 'blur(25px)',
              WebkitBackdropFilter: 'blur(25px)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              padding: '8px',
              marginTop: '4px'
            }
          }
        }}>
        {accountList?.map((account: AccountType) => (
          <MenuItem
            value={JSON.stringify({
              accountId: account.accountId,
              accountName: account.accountName
            })}
            key={account.accountName}>
            {account.accountName}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default AccountList;
