import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AlertComponenet from 'components/common/AlertComponents';
import { toast } from 'react-toastify';
import { JobsInfoReq, OnboardingJobsInfoSlice } from '../types';

const initialState: OnboardingJobsInfoSlice = {
  jobsInfo: {
    jobsInfoData: null,
    status: 'ready'
  }
};

const onboardingsJobsSlice = createSlice({
  name: 'onboardingsJobs',
  initialState,
  reducers: {
    getJobsInfoInit: (state, action: PayloadAction<JobsInfoReq>) => {
      state.jobsInfo = { status: 'loading', jobsInfoData: null };
    },
    getJobsInfoSuccess: (state, action) => {
      state.jobsInfo = { status: 'success', jobsInfoData: action?.payload };
    },
    getJobsInfoError: (state, action) => {
      state.jobsInfo = { status: 'error', jobsInfoData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs-info-error'
      });
    }
  }
});

export const { getJobsInfoInit, getJobsInfoSuccess, getJobsInfoError } =
  onboardingsJobsSlice.actions;

export default onboardingsJobsSlice.reducer;
