import React, { FC } from 'react';
import { AppBar, Toolbar, Typography, Divider, Tooltip, Box } from '@mui/material';
import AccountList from './accountList';
import { useAppSelector } from 'store/hooks';

const LeftMenu = () => {
  const { tenantData } = useAppSelector((state) => state.tenants);
  return (
    <>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{
          textTransform: 'capitalize'
        }}>
        {tenantData?.accountName}
      </Typography>
      <AccountList />
    </>
  );
};

export default LeftMenu;
