import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const stepperData = {
  awsSteps: [
    {
      step: 1,
      name: 'Authenticate account'
    },
    {
      step: 2,
      name: 'Track progress'
    }
  ],
  azureSteps: [
    {
      step: 1,
      name: 'Authenticate account'
    },
    {
      step: 2,
      name: 'Select subscriptions'
    },
    {
      step: 3,
      name: 'Track progress'
    }
  ],
  gcpSteps: [
    {
      step: 1,
      name: 'Authenticate account'
    },
    {
      step: 2,
      name: 'Select Project'
    },
    {
      step: 3,
      name: 'Track progress'
    }
]
}
 export const textBoxStyle = {
    background: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(221, 255, 82, 0.08)',
    borderRadius: '12px',
    mt: 1,
    mb: 0,
    height: 40,
    '& .MuiInputBase-input': {
      height: 40,
      padding: '0 12px',
      color: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        border: 'none'
      }
    },
    "& input[type=number]": {
    "-moz-appearance": "textfield",
    "-webkit-appearance": "none",
    "appearance": "textfield",
    "margin": 0
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    'margin': 0,
  },
  };

  export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      background: 'transparent'
    }
  });
  
