import { Box, Chip, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { FiltersPayloadType } from '../../types';
import { FC } from 'react';

interface FiltersBarProps {
  filters: FiltersPayloadType[];
  onDeleteFilter: (filter: FiltersPayloadType) => void;
  onResetFilters: () => void;
}

const FiltersBar: FC<FiltersBarProps> = ({ filters, onDeleteFilter, onResetFilters }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderTop="1px solid rgba(69, 69, 70, 1)"
      py={2}>
      <Box>
        {filters?.map((filter) => (
          <Chip
            color="primary"
            onDelete={() => onDeleteFilter(filter)}
            deleteIcon={<Close />}
            label={filter.value}
            key={filter.value}
            sx={{
              m: '4px',
              background: 'rgba(37, 41, 29, 0.5)',
              color: 'rgba(221, 255, 85, 1)',
              '.MuiChip-deleteIcon': {
                color: 'rgba(221, 255, 85, 1)',
                '&:hover': {
                  color: 'rgba(221, 255, 85, 1)',
                  cursor: 'pointer',
                },
              },
            }}
          />
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={1}
        px={1}
        sx={{
          color: 'rgba(221, 255, 85, 1)',
          cursor: 'pointer',
          '&:hover': {
            background: 'rgba(221, 255, 85, 0.08)',
            borderRadius: '8px',
          },
        }}
        onClick={onResetFilters}>
        <ReplayIcon />
        <Typography color="primary">Reset Filters</Typography>
      </Box>
    </Box>
  );
};

export default FiltersBar;
