import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  PlannedScheduleTriggerResponse,
  PlannedInstanceInfo,
  MountPointInfos,
  BulkOnboardSchedulePayload,
  ScheduleModalProps,
  BulkOnboardInstancesInfo
} from '../types';
import {
  setModalPreference,
  resetBulkOnboardSchedulePayload,
  setScheduleModalProps,
  setBulkOnboardSchedulePayload
} from '../store';

const usePlannedOnboarding = (plannedOnboaring?: PlannedScheduleTriggerResponse) => {
  const dispatch = useAppDispatch();
  const { plannedOnboarding } = useAppSelector((state) => state.unmanagedVm);
  const [selectedPlannedSchedule, setSelectedPlannedSchedule] =
    useState<PlannedScheduleTriggerResponse | null>(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState<string[]>([]);
  const [selectedInstanceInfo, setSelectedInstanceInfo] = useState<PlannedInstanceInfo[]>([]);
  const [openInnerTableInstancId, set0penInnerTableInstancId] = useState<string[]>([]);

  useEffect(() => {
    setSelectedInstanceId([]);
    setSelectedInstanceInfo([]);
    if (plannedOnboaring) setSelectedPlannedSchedule(plannedOnboaring);
  }, []);

  const disableToolTip = (instance: PlannedInstanceInfo) => {
    return instance?.mountPointInfos?.[0]?.partitionScheduleStatus !== 'IN_PROGRESS';
  };

  const getVolumeType = (mpInfos: MountPointInfos) => {
    if (mpInfos?.customRebootBasedOnboard) {
      return 'customReboot';
    }
    if (mpInfos?.mountPoint === '/' || mpInfos?.mountPoint === 'C') {
      return 'root';
    } else {
      return 'dataVolume';
    }
  };

  const constructPayload = (instanceInfo: PlannedInstanceInfo[]) => {
    let tenantAndInstance: BulkOnboardSchedulePayload | any = {};
    let payload = {};
    let scheduleModalProps: ScheduleModalProps = {
      scheduleInstanceInfo: [],
      totalSaving: 0,
      totalSavingWithConversion: 0,
      onboardType: null,
      customRebootBasedOnboard: false,
      cloudProviderList: [],

      onboardTimeGapInMinutes: 0,
      shrinkSupported: true,
      restartMessageDisabled: null,
      minimumTimeBeforeReboot: 0,
      popupInfo: null,
      isConversionCheckBoxChecked: true,
      onboardUpdate: true,
    };

    if (selectedPlannedSchedule) {
      const rebootReq: boolean = selectedPlannedSchedule?.rebootRequired;
      payload = {
        parentBulkTriggerId: selectedPlannedSchedule?.bulkTriggerId,
        osType: selectedPlannedSchedule?.instanceInfo?.[0]?.platformType,
        supportingScheduleInfo: {
          rebootRequired: rebootReq,
          cleanupDelayDays: 3
        },
        ...(rebootReq
          ? { rebootStartTime: selectedPlannedSchedule?.rebootStartTime }
          : { scheduledStartTime: selectedPlannedSchedule?.scheduledStartTime })
      };

      // schedule onboard props
      const mpInfo = instanceInfo?.[0]?.mountPointInfos?.[0];
      scheduleModalProps = {
        ...scheduleModalProps,
        onboardTimeGapInMinutes: mpInfo?.onboardTimeGapInMinutes,
        customRebootBasedOnboard: rebootReq,
        minimumTimeBeforeReboot: mpInfo?.minTimeBeforeReboot,
        onboardType: getVolumeType(mpInfo)
      };
    }

    // api payload
    const mountPointInfo: BulkOnboardInstancesInfo[] = [];
    instanceInfo?.map((instance: PlannedInstanceInfo) => {
      let payloads: any = {};
      let scheduleModalpayloads: any = {};
      const temMountPointList: string[] = [];
      payloads = { tenantId: instance?.tenantId, instanceId: instance?.instanceId };
      scheduleModalpayloads = {
        instanceId: instance?.instanceId,
        osName: instance?.osName,
        instanceName: instance?.instanceName,
        osShortName: instance?.osName
      };

      instance.mountPointInfos.forEach((mpinfo: MountPointInfos) => {
        payloads = {
          ...payloads,
          mountPoint: mpinfo?.mountPoint,
          size: mpinfo?.size,
          onboardingDiskType: mpinfo?.onboardingDiskType
        };
        temMountPointList.push(mpinfo?.mountPoint);
        mountPointInfo.push(payloads);
      });
      scheduleModalProps?.scheduleInstanceInfo.push({
        ...scheduleModalpayloads,
        mountPoint: temMountPointList
      });
    });

    tenantAndInstance = { ...payload, mountPointInfo: mountPointInfo };
    dispatch(setBulkOnboardSchedulePayload(tenantAndInstance));
    dispatch(setScheduleModalProps(scheduleModalProps));
  };

  const [allcheck, setAllCheck] = useState<boolean>(false);
  const [interminentCheck, setInterminentCheck] = useState<boolean>(false);

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (selectedPlannedSchedule?.instanceInfo) {
        constructPayload(selectedPlannedSchedule?.instanceInfo);
        setSelectedInstanceInfo(selectedPlannedSchedule?.instanceInfo);
        selectedPlannedSchedule?.instanceInfo?.map((instance: PlannedInstanceInfo) => {
          setSelectedInstanceId((prev: string[]) => [...prev, instance?.instanceId]);
        });
        setAllCheck(true);
      }
    } else {
      setAllCheck(false);
      setSelectedInstanceInfo([]);
      setSelectedInstanceId([]);
      dispatch(resetBulkOnboardSchedulePayload());
      dispatch(setScheduleModalProps(null));
    }
  };

  const handleSingleCheck = (instance: PlannedInstanceInfo) => {
    const name = instance?.instanceId;
    const selectedIndex = selectedInstanceId.indexOf(name);
    let newSelected: string[] = [];
    let tempInstance: PlannedInstanceInfo[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedInstanceId, name);
      tempInstance = tempInstance.concat(selectedInstanceInfo, instance);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedInstanceId.slice(1));
      tempInstance = tempInstance.concat(selectedInstanceInfo.slice(1));
    } else if (selectedIndex === selectedInstanceId.length - 1) {
      newSelected = newSelected.concat(selectedInstanceId.slice(0, -1));
      tempInstance = tempInstance.concat(selectedInstanceInfo.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedInstanceId.slice(0, selectedIndex),
        selectedInstanceId.slice(selectedIndex + 1)
      );
      tempInstance = tempInstance.concat(
        selectedInstanceInfo.slice(0, selectedIndex),
        selectedInstanceInfo.slice(selectedIndex + 1)
      );
    }
    setSelectedInstanceInfo(tempInstance);
    setSelectedInstanceId(newSelected);
    constructPayload(tempInstance);

    if (selectedPlannedSchedule) {
      setAllCheck(selectedPlannedSchedule?.instanceInfo.length === newSelected?.length);
      setInterminentCheck(selectedPlannedSchedule?.instanceInfo.length < newSelected?.length);
    }
  };

  const handleUpdateSchedule = () => {
    dispatch(setModalPreference('onboardUpdate'));
  };
  const handleCancelSchedule = () => {
    dispatch(setModalPreference('onboardCancel'));
  };

  const disableButtonCheck = () => {
    return selectedInstanceId?.length > 0 ? false : true;
  };

  const handleOpenInnerTable = (instanceId: string) => {
    if (openInnerTableInstancId.includes(instanceId)) {
      set0penInnerTableInstancId((prev) => prev.filter((iid) => iid !== instanceId));
    } else {
      set0penInnerTableInstancId((prev) => [...prev, instanceId]);
    }
  };


  return {
    handleOpenInnerTable,
    disableButtonCheck,
    handleUpdateSchedule,
    handleCancelSchedule,
    handleSingleCheck,
    handleAllCheck,
    constructPayload,
    disableToolTip,
    setSelectedInstanceId,
    setSelectedInstanceInfo,
    setAllCheck,
    selectedPlannedSchedule,
    allcheck,
    interminentCheck,
    selectedInstanceId,
    openInnerTableInstancId
  };
};

export default usePlannedOnboarding;