import { createSlice } from '@reduxjs/toolkit';
// local import
import { CloudProviderTypeSlice } from '../types';

const initialState: CloudProviderTypeSlice = {
  cloudType: '',
  permissions: { AUDIT: false, AUTO_SCALER: false },
  setup: undefined,
  cloudEntityStatusesList: undefined,
  cloudEntityStatusNumbers: {
    completionPercentage: 0,
    inprogressEntity: 0,
    noOfFailueEntity: 0,
    successEntity: 0,
    totalEntity: 0
  },
  inProgressCloudEntityStatuses: undefined,
  cloudLevelEntityStatusCount: undefined,
  inProgressGetCloudLevelEntityStatusCount: false,
  polling: {
    loading: false,
    delay: 3
  },
  onboardApiStatus: false,
  awsEpocIdentifier: ''
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setCloudProvider: (state, action) => {
      state.cloudType = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setSetUpRes: (state, action) => {
      state.setup = action.payload;
    },
    getCloudLevelEntityStatusCount: (state) => {
      state.inProgressGetCloudLevelEntityStatusCount = true
    },
    getCloudLevelEntityStatusCountFulfilled: (state,action) => {
      state.inProgressGetCloudLevelEntityStatusCount = false
      state.cloudLevelEntityStatusCount = action.payload.cloudEntityStates
    },
    getCloudLevelEntityStatusCountRejected: (state, action) => {
      state.inProgressGetCloudLevelEntityStatusCount = false
    },
    startPollingGetCloudEntityStatusesList: (state, action) => {
      state.polling.loading = true
    },
    pollingGetCloudEntityStatusesListFulfilled: (state,action) => {
      state.polling.loading = false
      state.inProgressCloudEntityStatuses = false
      state.cloudEntityStatusesList = action.payload?.cloudEntityStatusList
      state.cloudEntityStatusNumbers = {
        completionPercentage: action.payload?.completionPercentage,
        inprogressEntity: action.payload?.inProgressEntities,
        noOfFailueEntity: action.payload?.failedEntities,
        successEntity: action.payload?.successEntities,
        totalEntity: action.payload?.size
      }
      state.polling.delay = action.payload?.polling
    },
    stopPollingGetCloudEntityStatusesList: (state) => {
      state.polling.loading = false
    },
    
    resetCloudEntityStatusesList: (state) => {
      state.polling.loading = false
      state.cloudEntityStatusesList = undefined
      state.cloudEntityStatusNumbers = {
        completionPercentage: 0,
        inprogressEntity: 0,
        noOfFailueEntity: 0,
        successEntity: 0,
        totalEntity: 0
      }
      state.polling.delay = 3
    },
    setOnbaordApiStatus: (state, action) => {
      state.onboardApiStatus = action.payload
    },
    setAwsEpocIdentifier: (state, action) => {
      state.awsEpocIdentifier = action.payload
    }
  }
});

export const { 
  setCloudProvider,
  setPermissions, 
  setSetUpRes,
  getCloudLevelEntityStatusCount,
  getCloudLevelEntityStatusCountFulfilled,
  getCloudLevelEntityStatusCountRejected,
  startPollingGetCloudEntityStatusesList,
  pollingGetCloudEntityStatusesListFulfilled,
  stopPollingGetCloudEntityStatusesList,
  resetCloudEntityStatusesList,
  setOnbaordApiStatus,
  setAwsEpocIdentifier
} = accountsSlice.actions;

export default accountsSlice.reducer;
