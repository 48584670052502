import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../api';
import * as slice from '../store/onboardings.slice';
import { JobsInfoReq } from '../types';

export function* onGetJobsInfo(action: PayloadAction<JobsInfoReq>): SagaIterator {
  try {
    const data = yield call(api.getJobsInfo, action?.payload);
    yield put(slice.getJobsInfoSuccess(data));
  } catch (e: any) {
    yield put(slice.getJobsInfoError(e));
  }
}

// Watcher Saga
export function* onboardingsJobsSaga(): SagaIterator {
  yield takeLatest(slice.getJobsInfoInit.type, onGetJobsInfo);
}

export default onboardingsJobsSaga;
