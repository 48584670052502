import createSagaMiddleware from '@redux-saga/core';
import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import uploadReportSlice from 'features/audit/features/UploadReports/store/uploadReport.slice';
import tenantSlice from 'features/auth/store/tenant.slice';
import filterSlice from 'features/filter/store/filter.slice';
import searchSortSlice from 'components/searchAndSort/store/searchSort.slice';
import onboardSlice from 'features/onboard/store/onboard.slice';
import managedVmsSlice from 'features/scalar/components/managedVMs/store/managedVm.slice';
import scheduleSlice from 'features/scalar/components/shared/store/schedule.slice';
import unmanagedVmSlice from 'features/scalar/components/unmanagedVMs/store/unmanagedVm.slice';
import summarySlice from 'features/summary/store/summary.slice';
import auditSummarySlice from 'features/audit/features/DashboardAudit/store/auditDashboard.slice';
import fileListSlice from 'features/audit/features/FilesFilters/store/fileFilter.slice';
import accountsSlice from 'features/accounts/store/accounts.slice';
import scalerPoliciesSlice from 'features/scalar/components/managedVMs/store/scalerPolicies.slice';
import kubernetesSlice from 'features/kubernetes/store/kubernetes.slice';
import unmanagedVm from 'features/scalar/components/unManagedVm/store/unmanagedVm.slice';
import { kubRootSlice } from 'features/kubernetes/store/kubRoot.slice';
import { usersSlice } from 'features/users';
import rootSaga from './rootSaga';
import { jobsRootSlice } from 'features/jobs/store/jobsRoot.slice';

export const saga = createSagaMiddleware();

export const combineReducer = combineReducers({
  summary: summarySlice,
  tenants: tenantSlice,
  onboard: onboardSlice,
  filter: filterSlice,
  managedVms: managedVmsSlice,
  scheduleMp: scheduleSlice,
  unmanagedVms: unmanagedVmSlice,
  unmanagedVm: unmanagedVm,
  uploadReport: uploadReportSlice,
  fileList: fileListSlice,
  auditSummary: auditSummarySlice,
  accounts: accountsSlice,
  policies: scalerPoliciesSlice,
  users: usersSlice,
  searchSort: searchSortSlice,
  kubernetes: kubernetesSlice,
  kubernete: kubRootSlice,
  jobs: jobsRootSlice
});

// reset entire state on logout
export const rootReducer = (state: any, action: Action<any>) => {
  if (action.type === 'tenants/logout') {
    state = undefined;
  }
  return combineReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).prepend(saga)
});

export function getStoreWithState(preloadedState?: RootState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
}

saga.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof getStoreWithState>;
export type RootState = ReturnType<typeof store.getState>;

export default store;
