import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { SimpleTooltipStyle } from 'components/StylesTooltip';
import { ScheduleInstanceInfo } from '../../../types';
import VersionChip from '../../diskType/VersionChip';

const InstanceDetailsTooltip: FC = () => {
  const { scheduleModalPropsList } = useAppSelector((state) => state.unmanagedVm);
  return (
    <Box>
      {scheduleModalPropsList?.scheduleInstanceInfo?.map(
        (instance: ScheduleInstanceInfo, idx: number) => (
          <Box key={instance?.instanceId} mb={1}>
            <Typography variant="body2" gutterBottom>
              {instance?.instanceName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2">
                {`Mount point: ${instance?.mountPoint.join(',')}`}
              </Typography>
              <Box sx={{ height: 14, width: '2px', mx: 1.4, background: 'gray' }}></Box>
              <Typography variant="body2">{`OS: ${instance?.osShortName}`}</Typography>
              {instance?.instanceCompatibleForDiskCoversion && (
                <Box sx={{ ml: 2, mb: 2 }}>
                  <VersionChip diskCompatible={instance?.instanceCompatibleForDiskCoversion} />
                </Box>
              )}
            </Box>
            {scheduleModalPropsList?.scheduleInstanceInfo?.length - 1 !== idx && (
                <Divider sx={{ my: 1.2 }} />
              ) && <Divider sx={{ my: 1.2 }} />}
          </Box>
        )
      )}
    </Box>
  );
};

const SelectedInstanceInfo = () => {
  return (
    <SimpleTooltipStyle
      title={<InstanceDetailsTooltip />}
      placement="bottom"
      arrow={false}
      style={{ maxWidth: '100%' }}>
      <span style={{ display: 'inline-block' }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: '#c9ee4b' }}
          fontSize={14}
          lineHeight="28px"
          mb={0}>
          View Instances
        </Typography>
      </span>
    </SimpleTooltipStyle>
  );
};

export default SelectedInstanceInfo;
