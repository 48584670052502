import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import React, { FC, Suspense } from 'react';
// local import
import GlobalSpinner from 'components/GlobalSpinner';
import { DRAWERWIDTH } from 'constants/pages';
import './assets/style.scss';
import LeftNav from './component/leftNav';
import TopNav from './component/topNav';

const drawerWidth = DRAWERWIDTH;

type AppProviderProps = {
  style?: any;
  children?: React.ReactNode;
};

const Layout: FC<AppProviderProps> = ({ style, children }) => {
  return (
    <Box sx={{ display: 'flex' }} className="navbar">
      <CssBaseline />
      <TopNav />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              alignItems: 'center',
              pt: 1,
              pb: 2,
              backgroundColor: 'rgba(31, 32, 35, 0.28)',
              backdropFilter: 'blur(32.5px)',
              border: 'none'
            }
          }}
          open>
          <LeftNav />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          ...style,
          flexGrow: 1,
          p: 3.5,
          pt: 10,
          width: { sm: `calc(100% - ${drawerWidth}px)` }
        }}>
        <Suspense fallback={<GlobalSpinner />}>{children}</Suspense>
      </Box>
    </Box>
  );
};

export default Layout;
