import { Box } from '@mui/material';
import Buttons from 'components/buttons';
import { FC } from 'react';

interface ActionButtonsType {
  disableButtonCheck: () => boolean;
  handleCancelSchedule: (e: any) => void;
  handleUpdateSchedule: (e: any) => void;
}
const ActionButtons: FC<ActionButtonsType> = ({
  disableButtonCheck,
  handleCancelSchedule,
  handleUpdateSchedule,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 3, mr: 0.5 }}>
      <Buttons
        btnVarient="outlined"
        text="Cancel"
        sx={{
          color: '#c9ee4b',
          '&.Mui-disabled': {
            backgroundColor: 'rgba(221, 255, 85, 0.2)'
          }
        }}
        disabled={disableButtonCheck()}
        onClick={handleCancelSchedule}
      />
      <Buttons
        sx={{
          color: '#c9ee4b',
          '&.Mui-disabled': {
            backgroundColor: 'rgba(221, 255, 85, 0.2)'
          }
        }}
        btnVarient="outlined"
        text="Update"
        disabled={disableButtonCheck()}
        onClick={handleUpdateSchedule}
      />
    </Box>
  );
};

export default ActionButtons;
