import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import Close from '../../assets/images/close.svg';
import Error from '../../assets/images/error.svg';
import Onboard from '../../assets/images/onboard.svg';
import Success from '../../assets/images/success.svg';
import { Link } from 'react-router-dom';

interface AlertComponenetProps {
  msg?: string;
  hideHeading?: boolean;
  toastType?: 'onboard' | 'error' | 'success';
  title?: string;
  icon?: string;
  link?: { link: string; title: string };
}
const AlertComponenet: FC<AlertComponenetProps> = ({
  msg,
  title,
  hideHeading = false,
  toastType = 'error',
  icon,
  link,
}) => {
  return (
    <div>
      <Box
        sx={{
          borderRadius: '8px',
          border: '2px solid rgba(221, 255, 85, 0.08)',
          background: '#22261C',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          padding: '20px 16px',
          width: '100%'
        }}>
        <Box
          sx={{
            width: 38,
            height: 38,
            padding: 1,
            backgroundColor: 'rgba(221, 255, 85, 0.20)',
            borderRadius: '99px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {icon && <img src={icon} alt="icon" />}
          {!icon && toastType === 'error' && <img src={Error} alt="error" />}
          {!icon && toastType === 'onboard' && <img src={Onboard} alt="onboard" />}
          {!icon && toastType === 'success' && <img src={Success} alt="Success" />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
          }}
        >
          <Box>
            {(title || toastType === 'error') && hideHeading === false && (
              <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={1} color="white">
                {title ? title : 'Oops! Something went wrong...'}
              </Typography>
            )}
            {msg &&
              String(msg)?.split('\n').map((text: string) => (
                <Typography key={text} variant="body2" gutterBottom fontSize={16} lineHeight="24px" mb={0} sx={{ color: 'rgba(255, 255, 255, 0.80)' }}>
                  {text}
                </Typography>
              ))
            }
          </Box>
          {link && (
            <Link 
              to={link.link}
              style={{
                color: '#DF5',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
              }}
            >
              {link.title}
            </Link>
          )}
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            marginLeft: 'auto'
          }}>
          <img src={Close} alt="X" />
        </Box>
      </Box>
    </div>
  );
};

export default AlertComponenet;
