import { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../api';
import * as slice from './jobsShared.slice'
import { SummaryReq, JobsListyReq } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';

export function* onGetSummary(action: PayloadAction<SummaryReq>): SagaIterator {
  try {
    const data = yield call(api.getSummary, action?.payload);
    yield put(slice.getSummarySuccess(data));
  } catch (e: any) {
    yield put(slice.getSummaryError(e));
  }
}

export function* onGetJLScheduledInProgress(action: PayloadAction<JobsListyReq>): SagaIterator {
  try {
    const data = yield call(api.getJobsList, action?.payload);
    yield put(slice.getJLScheduleInprogressSuccess(data));
  } catch (e: any) {
    yield put(slice.getJLScheduleInprogressError(e));
  }
}

export function* onGetJLCompleted(action: PayloadAction<JobsListyReq>): SagaIterator {
  try {
    const data = yield call(api.getJobsList, action?.payload);
    yield put(slice.getJLCompletedSuccess(data));
  } catch (e: any) {
    yield put(slice.getJLCompletedError(e));
  }
}

// Watcher Saga
export function* jobsSharedSagas(): SagaIterator {
  yield takeLatest(slice.getSummaryInit.type, onGetSummary);
  yield takeLatest(slice.getJLScheduleInprogressInit.type, onGetJLScheduledInProgress);
  yield takeLatest(slice.getJLCompletedInit, onGetJLCompleted);
}

export default jobsSharedSagas;
