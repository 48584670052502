import { Environments } from 'types/common';
import * as signConfig from './constants/signin';

interface Environment {
  [x: string]: Configuration;
}

interface Configuration {
  ApiBaseUrl: string;
  oktaClientId: string;
  oktaIssuer: string;
  oktaRedirectUri: string;
  oktaIdp: string;
  disableHttps: boolean;
  azureRedirectUri: string;
  azureClientId: string;
  azureAuthority: string;
  azureScope: string;
  enableMicrosoftLogin: boolean;
  azureClientIdAccounts: string;
  azureRedirectUriAccounts: string;
  gcpClientId: string;
  gcpScope: string;
  amplitudeKey?: string;
  diasableFeature?: string;
}

const configs: Environment = {
  dev: {
    ApiBaseUrl: 'https://dashboard-staging.lucidity.cloud',
    oktaClientId: '0oa5le2zjjoByEcWN5d7',
    oktaIssuer: 'https://auth.lucidity.dev/oauth2/default',
    oktaRedirectUri: '/login/callback',
    oktaIdp: '0oa5lsp7b5YBXx0lk5d7',
    disableHttps: true,
    azureRedirectUri: '/signin/callback',
    azureClientId: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureAuthority: 'https://login.microsoftonline.com/common',
    azureScope: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5/.default',
    enableMicrosoftLogin: true,
    azureClientIdAccounts: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureRedirectUriAccounts: '/accounts/callback',
    gcpClientId: '398079620121-vnaajcjg5574u7v6ciqm52653iqr918c.apps.googleusercontent.com',
    gcpScope: 'https://www.googleapis.com/auth/cloud-platform',
    amplitudeKey: 'a34ff559e5ab27ce970b2ac387d48ac0',
  },
  uat: {
    ApiBaseUrl: 'https://dashboard-staging.lucidity.cloud',
    oktaClientId: '0oa5le2zjjoByEcWN5d7',
    oktaIssuer: 'https://auth.lucidity.dev/oauth2/default',
    oktaRedirectUri: '/login/callback',
    oktaIdp: '0oa5lsp7b5YBXx0lk5d7',
    disableHttps: true,
    azureRedirectUri: '/signin/callback',
    azureClientId: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureAuthority: 'https://login.microsoftonline.com/common',
    azureScope: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5/.default',
    enableMicrosoftLogin: true,
    azureClientIdAccounts: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureRedirectUriAccounts: '/accounts/callback',
    gcpClientId: '398079620121-vnaajcjg5574u7v6ciqm52653iqr918c.apps.googleusercontent.com',
    gcpScope: 'https://www.googleapis.com/auth/cloud-platform',
    amplitudeKey: 'a34ff559e5ab27ce970b2ac387d48ac0',
  },
  qa: {
    ApiBaseUrl: 'http://ec2-13-200-233-146.ap-south-1.compute.amazonaws.com:8087',
    oktaClientId: '0oa5le2zjjoByEcWN5d7',
    oktaIssuer: 'https://auth.lucidity.dev/oauth2/default',
    oktaRedirectUri: '/login/callback',
    oktaIdp: '0oa5lsp7b5YBXx0lk5d7',
    disableHttps: true,
    azureRedirectUri: '/signin/callback',
    azureClientId: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureAuthority: 'https://login.microsoftonline.com/common',
    azureScope: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5/.default',
    enableMicrosoftLogin: true,
    azureClientIdAccounts: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureRedirectUriAccounts: '/accounts/callback',
    gcpClientId: '398079620121-vnaajcjg5574u7v6ciqm52653iqr918c.apps.googleusercontent.com',
    gcpScope: 'https://www.googleapis.com/auth/cloud-platform',
    amplitudeKey: 'a34ff559e5ab27ce970b2ac387d48ac0',
  },
  feature: {
    ApiBaseUrl: 'https://dashboard-staging.lucidity.cloud',
    oktaClientId: '0oa5le2zjjoByEcWN5d7',
    oktaIssuer: 'https://auth.lucidity.dev/oauth2/default',
    oktaRedirectUri: '/login/callback',
    oktaIdp: '0oa5lsp7b5YBXx0lk5d7',
    disableHttps: true,
    azureRedirectUri: '/signin/callback',
    azureClientId: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureAuthority: 'https://login.microsoftonline.com/common',
    azureScope: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5/.default',
    enableMicrosoftLogin: true,
    azureClientIdAccounts: 'e449d7d1-252f-4bb1-9693-baa9b3db40a5',
    azureRedirectUriAccounts: '/accounts/callback',
    gcpClientId: '398079620121-vnaajcjg5574u7v6ciqm52653iqr918c.apps.googleusercontent.com',
    gcpScope: 'https://www.googleapis.com/auth/cloud-platform',
    amplitudeKey: 'a34ff559e5ab27ce970b2ac387d48ac0',
  },
  prod: {
    ApiBaseUrl: 'https://dash-back.lucidity.dev',
    oktaClientId: '0oa5lqr32e61eqhow5d7',
    oktaIssuer: 'https://auth.lucidity.dev/oauth2/default',
    oktaRedirectUri: '/login/callback',
    oktaIdp: '0oa5mfz6pldCpR23l5d7',
    disableHttps: true,
    azureRedirectUri: '/signin/callback',
    azureClientId: '4f2c2c1f-372a-4904-b13d-11e2467679f2',
    azureAuthority: 'https://login.microsoftonline.com/common',
    azureScope: '4f2c2c1f-372a-4904-b13d-11e2467679f2/.default',
    enableMicrosoftLogin: true,
    azureClientIdAccounts: '4f2c2c1f-372a-4904-b13d-11e2467679f2',
    azureRedirectUriAccounts: '/accounts/callback',
    gcpClientId: '423178348545-usv1h3bpbe4hljtqkrcuc3vk92uv8kgl.apps.googleusercontent.com',
    gcpScope: 'https://www.googleapis.com/auth/cloud-platform',
    amplitudeKey: 'd434ce2c03ad14451b3747fcfa19844d'
  },
  k8s: {
    ApiBaseUrl: signConfig.BASE_URL,
    oktaClientId: signConfig.OktaClientId,
    oktaIssuer: signConfig.OktaIssuer,
    oktaRedirectUri: signConfig.OktaRedirectUri,
    oktaIdp: signConfig.OktaIDP,
    disableHttps: signConfig.OktaDisableHTTPSCheck,
    azureRedirectUri: signConfig.AzureRedirectUri,
    azureClientId: signConfig.AzureClientID,
    azureAuthority: signConfig.AzureAuthority,
    azureScope: signConfig.AzureScope,
    enableMicrosoftLogin: signConfig.EnableMicrosoftLogin,
    azureClientIdAccounts: signConfig.azureClientIDAccounts,
    azureRedirectUriAccounts: signConfig.azureRedirectUriAccounts,
    gcpClientId: signConfig.gcpClientId,
    gcpScope: signConfig.gcpScope,
    amplitudeKey: signConfig.amplitudeKey,
  }
};

const currentEnvironment: Environments =
  (process.env.REACT_APP_STAGE?.trim() as Environments) || 'dev';
const config = configs[currentEnvironment];

export const ApiBaseUrl = config.ApiBaseUrl;

const getEnvironmentName = (environment: Environments) => {
  let envName = '';
  if (environment === 'dev') {
    envName = 'dev';
  } else if (environment === 'uat' || environment === 'feature' || environment === 'qa') {
    envName = 'uat';
  } else if (environment === 'k8s' || environment === 'prod') {
    envName = 'prod';
  }
  return envName as Environments;
};

export const environmentName: Environments = getEnvironmentName(currentEnvironment);

export default config;
