import GlobalSpinner from 'components/GlobalSpinner';
import Layout from 'layouts';
import { getTenantPending } from 'features/auth/store';
import useAuth from 'hooks/useAuth';
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isTokenAndAuthTypeValid, isUndefineNull } from 'utils/common';
import { removeItem } from 'utils/localstorage';
import storage from 'utils/storage';
import { toast } from 'react-toastify';
import { resetSearchSort } from 'components/searchAndSort/store/searchSort.slice';

interface ProtectedRouteProps {
  sideLayout?: boolean;
  style?: any;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ style, sideLayout = true }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { tenantData, inProcess } = useAppSelector((state) => state.tenants);
  const ispreviousRouteSignin = document?.referrer?.includes('signin');
  // Remove toast on route change
  toast.dismiss();

  useAuth();

  // reset sort and search on route change
  dispatch(resetSearchSort({all: true}))
  
  useEffect(() => {
    if (!tenantData && !inProcess && !ispreviousRouteSignin && isTokenAndAuthTypeValid()) {
      dispatch(getTenantPending());
    }
  }, []);

  if (isUndefineNull(storage.getAuthType())) {
    navigate(`/signin`);
  }

  if (!pathname.includes('audit') && !pathname.includes('accounts')) {
    if (storage.getIsNewUser() && inProcess === false) {
      navigate('/accounts');
    }
  }

  // remove single tenant from local storage
  if (!pathname.includes('onboard')) {
    removeItem('lucidity-tenant');
    storage.clearRegion();
  }

  if (!ispreviousRouteSignin && storage.getAuthType() === 'okta') {
    if (!isTokenAndAuthTypeValid()) {
      setTimeout(() => {
        if (!isTokenAndAuthTypeValid()) {
          navigate(`/signin`);
        } else {
          navigate('/dashboard');
        }
      }, 400);
    }
  } else if (storage.getAuthType() === 'azure') {
    if (isUndefineNull(storage.getToken()) || isUndefineNull(storage.getAuthType())) {
      navigate(`/signin`);
    }
  }

  if (isUndefineNull(storage.getToken())) return <GlobalSpinner />;

  // render without side layout
  if (!sideLayout) return <Outlet />;

  // render with side layout
  return <Layout style={style}>{!inProcess ? <Outlet /> : <GlobalSpinner />}</Layout>;
};

export default ProtectedRoute;
