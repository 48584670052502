import { FC } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { ProgressContainer } from './ProgressContainer';
import { GlobalBarLoader } from 'components/GlobalSpinner';
import CustomToolTip from 'components/StylesTooltip';
import { CloudEntityStatusesListType } from 'features/accounts/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { stackSetFaiureReasonUrl } from 'features/accounts/features/utils/constant';

interface PropsType {
  cloudEntityStatusesList: CloudEntityStatusesListType[] | undefined;
  cloudType: string;
}

const MultiTrackProgress: FC<PropsType> = ({ cloudEntityStatusesList, cloudType }) => {
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'SUCCESS':
        return <CheckCircleIcon color="success" />;
      case 'FAILED':
        return <WarningIcon sx={{ color: '#E15D5D' }} />;
      default:
        return <CircularProgress size="25px" />;
    }
  };

  const handleReasonText = (reason: string, category: string) => {
    if (cloudType === 'AWS' && category === 'Role Creation Failed') {
      return (
        <>
          <Typography>
            {reason}{' '}
            <Link
              style={{ color: 'rgb(150, 85, 204)', textDecoration: 'underline', cursor: 'pointer' }}
              target="_blank"
              rel="noopener noreferrer"
              href={stackSetFaiureReasonUrl}>
              Common reasons for StackSet execution failure.
            </Link>
          </Typography>
        </>
      );
    } else return <Typography>{reason}</Typography>;
  };

  return (
    <>
      <ProgressContainer />
      <Grid item xs={8} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
        <List sx={{ borderRadius: 2, backgroundColor: 'rgba(255, 255, 255, 0.05))' }}>
          {cloudEntityStatusesList?.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                my: 1,
                borderRadius: '8px',
                marginTop: index === 0 ? 0 : '',
                border: '1px solid #454546'
              }}>
              <ListItemIcon>
                {getStatusIcon(item.status)}
                <Box sx={{ width: '1px', bgcolor: '#565656', height: '32px', ml: 2 }}></Box>
              </ListItemIcon>
              <ListItemText
                primary={cloudType !== 'AWS' ? item.cloudEntityName : item.cloudEntityId}
                secondary={cloudType === 'AWS' ? item.cloudEntityName : ''}
                sx={{
                  color: item.status === 'FAILED' ? 'error.main' : 'inherit'
                }}
              />
              {(item.status === 'IN_PROGRESS' || item.status === 'PENDING') && (
                <GlobalBarLoader CircleType="bar" />
              )}
              {item.status === 'FAILED' && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position={cloudType === 'AWS' ? 'relative' : 'unset'}>
                  <Typography variant="body2" color="#E15D5D" mx={3}>
                    {item.category}
                  </Typography>
                  <Box mb={cloudType === 'AWS' ? 6 : 0}>
                    <CustomToolTip
                      iconType="primaryE"
                      iconImgStyle={{ height: '20px', width: '20px' }}
                      text={handleReasonText(item.reason, item.category)}
                    />
                  </Box>
                </Box>
              )}
              {item.status === 'SUCCESS' && cloudType !== 'AWS' && (
                <Box display="flex" gap={1} sx={{ color: 'rgba(255, 255, 255, 0.80)' }}>
                  <Typography variant="subtitle2" sx={{ mt: '2px' }}>
                    Instance: {item.vmCount}
                  </Typography>
                  <Typography>•</Typography>
                  <Typography variant="subtitle2" sx={{ mt: '2px' }}>
                    Disks: {item.diskCount}
                  </Typography>
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </>
  );
};

export default MultiTrackProgress;
