import { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetCloudEntityStatusesList,
  startPollingGetCloudEntityStatusesList,
  stopPollingGetCloudEntityStatusesList
} from 'features/accounts/store';
import Buttons from 'components/buttons';
import SingleAccountTrackProgress from './SingleAccountTrackProgress';
import { getTenantPending } from 'features/auth/store';
import MultiTrackProgress from './MultiAccountTrackProgress';

const TrackProgress = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cloudEntityStatusesList, cloudEntityStatusNumbers, cloudType, polling } = useAppSelector(
    (state) => state.accounts
  );
  const isOnbardingCompleted = cloudEntityStatusNumbers?.completionPercentage === 100;

  useEffect(() => {
    const payload = { cloudProvider: cloudType, pollingDelay: polling.delay };
    dispatch(startPollingGetCloudEntityStatusesList(payload));
    return () => {
      dispatch(stopPollingGetCloudEntityStatusesList());
      dispatch(resetCloudEntityStatusesList());
    };
  }, []);

  useEffect(() => {
    if (!polling.delay && !polling.loading) dispatch(stopPollingGetCloudEntityStatusesList());
  }, [polling.delay]);

  const handleSubmit = () => {
    if (isOnbardingCompleted) {
      dispatch(getTenantPending());
      navigate('/accounts');
    }
  };

  const renderProgressComponent = (cloudType === 'AWS' && cloudEntityStatusesList?.length === 1)
  ? (
    <SingleAccountTrackProgress
      cloudEntity={cloudEntityStatusesList[0]}
      isOnbardingCompleted={isOnbardingCompleted}
      handleSubmit={handleSubmit}
    />
  )
  : (
    <MultiTrackProgress 
      cloudEntityStatusesList={cloudEntityStatusesList}
      cloudType={cloudType} 
    />
  );

  return (
    <>
      <Box gap={2} sx={{ display: 'flex', marginTop: '32px', minWidth: '960px' }}>
        {polling.loading ? (
          <Grid container>
            <Grid
              data-testid="globar-spinner"
              container
              height="60vh"
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ paddingRight: '50px' }}>
              <Grid item mb={2}>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography fontSize={24} fontWeight={700}>
                  Fetching Account Details
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontSize={16} fontWeight={400}>
                  Please don’t refresh the page or go back
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) :
        renderProgressComponent
        }
      </Box>
      {!(cloudEntityStatusesList?.length === 1) && (
        <Box
          sx={{
            display: 'flex',
            gap: '2px',
            width: '100%',
            left: 0,
            bottom: 0,
            position: 'fixed',
            justifyContent: 'flex-end',
            background: 'rgb(35, 36, 38)',
            padding: '15px'
          }}>
          {
            <Buttons
              disabled={!isOnbardingCompleted}
              btnVarient="contained"
              text="Finish"
              onClick={handleSubmit}
              sx={{ width: 100, mr: 2 }}
            />
          }
        </Box>
      )}
    </>
  );
};

export default TrackProgress;
