import { useEffect, useState } from 'react';
import { filterTabType } from '../utils/helper';
import { FilterListType, FiltersPayloadType, FilterType } from '../types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setFilters } from '../store';
import { resetPag } from 'features/filter/store';


const useFilters = (handleClose: () => void, anchorEl: HTMLElement | null) => {
  const dispatch = useAppDispatch()
  const [tabValue, setTabValue] = useState<string>(filterTabType.STATUS);
  const { filters } = useAppSelector((state) => state.unmanagedVm);
  const [selectedFilters, setSelectedFilters] = useState<FilterListType>({
    STATUS: [],
    VOLUME: [],
    OS: []
  });
  const filtersArr = ['Status', 'Volume Type', 'OS'];

  useEffect(() => {
    const initFilter: FilterListType = {
      STATUS: [],
      VOLUME: [],
      OS: []
    };
    filters?.map((filter) => {
      initFilter[filter.type as FilterType].push(filter.value);
    });
    setSelectedFilters(initFilter);
  }, [anchorEl]);

  const tabValueHandler = (value: string) => {
    setTabValue(value);
  };

  const isChecked = (value: string) => {
    const temp = selectedFilters[tabValue as FilterType].indexOf(value);
    return temp > -1;
  };

  const handleResetFilters = () => {
    setSelectedFilters({
      STATUS: [],
      VOLUME: [],
      OS: []
    });
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if (checked) {
      Object.keys(selectedFilters).forEach((key) => {
        if (tabValue === key) {
          selectedFilters[tabValue as FilterType].push(value);
        }
      });
      setSelectedFilters({ ...selectedFilters });
    } else {
      let tempArr: string[] = [];
      Object.keys(selectedFilters).forEach((key) => {
        if (tabValue === key) {
          tempArr = selectedFilters[tabValue as FilterType].filter((el) => el !== value);
        }
      });
      selectedFilters[tabValue as FilterType] = tempArr;
      setSelectedFilters({ ...selectedFilters });
    }
  };

  const handleApplyFilters = () => {
    const tempFilters: FiltersPayloadType[] = [];
    Object.keys(selectedFilters).forEach((filterType) => {
      selectedFilters[filterType as FilterType].forEach((filterVal) => {
        tempFilters.push({
          type: filterType,
          value: filterVal
        });
      });
    });
    dispatch(setFilters(tempFilters));
    dispatch(resetPag());
    handleClose();
  };

  return {
    tabValue,
    tabValueHandler,
    filtersArr,
    isChecked,
    handleResetFilters,
    handleCheckbox,
    handleApplyFilters,
    selectedFilters
  };
};

export default useFilters;
