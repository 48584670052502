import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { checkTenantLenghtInFilter } from 'utils/common';
import {
  getUnManagedVm,
  resetBulkOnboardSchedulePayload,
  setModalPreference,
  setScheduleModalProps
} from '../store';

const useUnManagedVm = () => {
  const dispatch = useAppDispatch();
  const tenantData = useAppSelector((state) => state.tenants);
  const { requestFilter } = useAppSelector((state) => state.filter);
  const { filterQuery, searchQuery } = useAppSelector((state) => state.searchSort);
  const { status, bulkSchedule, filters: unmanagedVmFilters } = useAppSelector((state) => state.unmanagedVm);

  const unmnagedVmApiCall = () => {
    dispatch(
      getUnManagedVm({
        filter: {...requestFilter, unmanagedVmFilters },
        query: searchQuery,
        unmanagedVmFilters,
        ...filterQuery
      })
    );
  };

  useEffect(() => {
    if (
      !tenantData?.inProcess &&
      tenantData?.inProcess !== undefined &&
      status !== 'loading' &&
      checkTenantLenghtInFilter(requestFilter) > 0
    ) {
      if (filterQuery?.sortUnvmQuery || filterQuery?.rebootQuery || searchQuery || unmanagedVmFilters) {
        unmnagedVmApiCall();
      } else {
        dispatch(getUnManagedVm({ filter: requestFilter }));
      }
    }
  }, [requestFilter, filterQuery, searchQuery, unmanagedVmFilters]);

  useEffect(() => {
    if (bulkSchedule?.status === 'success' && checkTenantLenghtInFilter(requestFilter) > 0) {
      unmnagedVmApiCall();
    }
    if (bulkSchedule?.status !== 'loading') {
      dispatch(setModalPreference(null));
      dispatch(resetBulkOnboardSchedulePayload());
      dispatch(setScheduleModalProps(null));
    }
  }, [bulkSchedule]);

  return {};
};

export default useUnManagedVm;
