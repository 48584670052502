import { FC } from 'react';
import { Box, Button, Checkbox, Divider, List, ListItem, Popover, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { filterList, filterTabType } from '../../utils/helper';
import { FilterListKeys, FilterType } from '../../types';
import { useAppSelector } from 'store/hooks';
import useFilters from '../../hooks/useFilters';

interface FilterModalPropsType {
  handleClose: () => void;
  anchorEl: HTMLElement | null;
}

const GreenDot = () => {
  return (
    <Box
      sx={{
        mt: 1,
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        background: 'rgba(221, 255, 85, 1)'
      }}
    />
  );
};

const FilterModal: FC<FilterModalPropsType> = ({  handleClose, anchorEl }) => {
  const { tenantData } = useAppSelector((state) => state.tenants);

  const {
    tabValue,
    filtersArr,
    isChecked,
    handleResetFilters,
    handleCheckbox,
    handleApplyFilters,
    tabValueHandler,
    selectedFilters
  } = useFilters(handleClose, anchorEl);

  const tabStyle = (value: string) => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      p: 2,
      cursor: 'pointer',
      background: value === tabValue ? 'rgba(221, 255, 85, 0.1)' : '',
      color: value === tabValue ? 'rgba(221, 255, 85, 1)' : '',
    };
  };

  const getFiltersCheckbox = (filter: string) => {
    let tempFilter = filter;
    if (tabValue === filterTabType.VOLUME) {
      const firstCharUpper = filter.charAt(0).toUpperCase() + filter.slice(1);
      tempFilter = `${firstCharUpper} Volumes`;
    }
    return (
      <ListItem key={tempFilter}>
        <Checkbox checked={isChecked(filter)} value={filter} onChange={handleCheckbox} />
        {tempFilter}
      </ListItem>
    );
  };

  const getFiltersTabContainer = (filterTabValue: string) => {
    const tempFilterTabValue = filterTabValue.split(' ')[0].toLocaleUpperCase();
    return (
      <Box
        key={filterTabValue}
        sx={{ ...tabStyle(tempFilterTabValue) }}
        onClick={() => tabValueHandler(tempFilterTabValue)}>
        <Typography sx={{  color: tabValue === tempFilterTabValue ? 'rgba(221, 255, 85, 1)' : '' }}>
          {filterTabValue}
        </Typography>
        {selectedFilters[tempFilterTabValue as FilterType].length > 0 && <GreenDot />}
      </Box>
    );
  };

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        container={document.body}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '800px',
            borderRadius: '12px',
            border: '1px solid rgba(225, 225, 225,0.4)',
            backgroundColor: 'rgba(22, 23, 24, 0.28)',
            backdropFilter: 'blur(25px)'
          }
        }}>
        <Box border="1px solid rgba(69, 69, 70, 1)" borderRadius={'8px'} height={600}>
          <Box
            display="flex"
            justifyContent="space-between"
            mx={1}
            p={2}
            onClick={handleResetFilters}>
            <Typography fontSize={20} fontWeight={700}>
              Filter By
            </Typography>
            <Box
              display="flex"
              gap={1}
              sx={{
                color: 'rgba(221, 255, 85, 1)',
                cursor: 'pointer',
                '&:hover': {
                  background: 'rgba(221, 255, 85, 0.08)',
                  borderRadius: '8px'
                }
              }}>
              <ReplayIcon />
              <Typography color="primary">Reset Filters</Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 1, color: 'rgba(69, 69, 70, 1)', width: '100%' }} />
          <Box
            display="flex"
            justifyContent="space-between"
            height="75%"
            borderBottom="1px solid rgba(225, 225, 225,0.4)">
            <Box flex={2}>
              {filtersArr.map((filterTabValue) => getFiltersTabContainer(filterTabValue))}
            </Box>

            <Box sx={{ borderLeft: '1px solid rgba(225, 225, 225,0.4)' }} flex={4}>
              <List sx={{ overflowY: 'scroll', height: '90%' }}>
                {tabValue === filterTabType.OS
                  ? tenantData?.availableOsList?.map((filter) => (
                      <ListItem key={filter}>
                        <Checkbox
                          checked={isChecked(filter)}
                          value={filter}
                          onChange={handleCheckbox}
                        />
                        {filter}
                      </ListItem>
                    ))
                  : filterList[tabValue as FilterListKeys].map((filter) =>
                      getFiltersCheckbox(filter)
                    )}
              </List>
            </Box>
          </Box>
          <Box p={2} display="flex" justifyContent="flex-end" gap={1}>
            <Box>
              <Button sx={{ color: 'rgba(221, 255, 85, 1)' }} variant="text" onClick={handleClose}>
                Close
              </Button>
            </Box>
            <Box>
              <Button variant="contained" onClick={handleApplyFilters}>
                Apply Filter
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default FilterModal;
