import { Box} from '@mui/material';
import { StyledPagination } from 'components/common/styledMUIcomp/StyledPaginatino';
import usePagination from 'hooks/usePagination';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useUnManagedVm from '../hooks/useUnManagedVm';
import InstanceToOnboardTable from './instanceToOnboardTable';
import PlannedOnboarding from './plannedOnboarding';
import SearchSortUmvm from './searchSort';
import {setFilters, updateFilters } from '../store';
import { resetPag } from 'features/filter/store';
import { FiltersPayloadType} from '../types';
import FiltersBar from './searchSort/FiltersBar';

const UnManagedVmContainer = () => {
  const dispatch = useAppDispatch();
  const { status, unmanagedVmsData, modalPreference, filters } = useAppSelector(
    (state) => state.unmanagedVm
  );
  useUnManagedVm();
  const { handlePageChange, currentPage } = usePagination();

  const handleDeleteFilters = (filter: FiltersPayloadType) => {
    dispatch(updateFilters(filter));
  };

  const handleResetFilters = () => {
    dispatch(resetPag());
    dispatch(setFilters([]));
  };

  return (
    <>
      {modalPreference !== 'onboard' && <PlannedOnboarding />}
      {filters?.length > 0 && (
        <FiltersBar
          filters={filters}
          onDeleteFilter={handleDeleteFilters}
          onResetFilters={handleResetFilters}
        />
      )}
      <SearchSortUmvm />
      <InstanceToOnboardTable />

      {status !== 'loading' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4
          }}>
          <StyledPagination
            variant="outlined"
            shape="rounded"
            color="primary"
            count={unmanagedVmsData?.meta?.totalPage}
            page={currentPage + 1}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};

export default UnManagedVmContainer;
