import { oktaConfiguration } from 'features/auth/config/oktaConfig';
import { useCookies } from 'hooks/useCookies';
import { TenantResponseType } from '../features/auth/types';
import { getItem, removeItem, setItem } from './localstorage';

const storagePrefix = 'lucidity-';

const { addCookie, readCookie } = useCookies();
const storage = {
  getToken: () => {
    const token = readCookie(`${storagePrefix}accessToken`);
    return token;
  },
  setToken: (token: string) => {
    addCookie(`${storagePrefix}accessToken`, token);
  },
  clearCookies: () => {
    document.cookie.split(';').forEach(function (c: any) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  },

  setActiveDate: (dates: any) => {
    setItem(`${storagePrefix}dates`, JSON.stringify(dates));
  },

  getActiveDates: () => {
    return getItem(`${storagePrefix}dates`, 'json');
  },

  setAuthType: (type: string) => {
    setItem(`${storagePrefix}authType`, type);
  },
  getAuthType: () => {
    return getItem(`${storagePrefix}authType`);
  },
  setTenant: (tenant: any) => {
    setItem(`${storagePrefix}tenant`, JSON.stringify(tenant));
  },
  getTenant: () => {
    return getItem(`${storagePrefix}tenant`, 'json');
  },
  setTokenExpire: (expire: string) => {
    setItem(`${storagePrefix}expireAt`, expire);
  },
  getTokenExpire: () => {
    return getItem(`${storagePrefix}expireAt`);
  },
  setMultiTenant: (tenant: any) => {
    setItem(`${storagePrefix}multi-tenant`, JSON.stringify(tenant));
    storage.setXTenantIdList();
  },
  getMultiTenant: () => {
    return getItem(`${storagePrefix}multi-tenant`, 'json');
  },
  clearAllStorage: () => {
    if (storage.getAuthType() === 'okta') {
      oktaConfiguration.revokeAccessToken().then(() => {
        setTimeout(() => {
          const isSignin = window.location.pathname === '/signin';
          if (!isSignin) window.location.href = '/signin';
        }, 1600);
      });
    } else {
      window.location.href = '/signin';
    }

    oktaConfiguration.tokenManager.clear();
    localStorage.clear();
    sessionStorage.clear();
    document.cookie =
      `${storagePrefix}accessToken` + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
  setIsNewUser: (tenantData: TenantResponseType) => {
    const isnewUser = Object.keys(tenantData?.tenantInfoList)?.length;
    // if new user then length wll be 0
    setItem(`${storagePrefix}userType`, isnewUser);
  },
  getIsNewUser: () => {
    return getItem(`${storagePrefix}userType`) === '0';
  },
  setEnvTag: (tag: string[]) => {
    setItem(`${storagePrefix}envtag`, tag);
  },
  getEnvTag: () => {
    return getItem(`${storagePrefix}envtag`);
  },
  setGraphToken: (token: string) => {
    setItem(`${storagePrefix}graph`, token);
  },
  setManagentToken: (token: string) => {
    setItem(`${storagePrefix}management`, token);
  },
  getGraphToken: () => {
    return getItem(`${storagePrefix}graph`);
  },
  getManagentToken: () => {
    return getItem(`${storagePrefix}management`);
  },
  setGCPToken: (token: string) => {
    setItem(`${storagePrefix}gcp-token`, token);
  },
  getGCPToken: () => {
    return getItem(`${storagePrefix}gcp-token`);
  },
  clearGraphAndManagementToken: () => {
    removeItem(`${storagePrefix}management`);
    removeItem(`${storagePrefix}graph`);
    removeItem(`${storagePrefix}account-permission`);
    removeItem(`${storagePrefix}gcp-token`);
    sessionStorage.clear();
  },
  setAccountsPermission: (payload: any) => {
    setItem(`${storagePrefix}account-permission`, JSON.stringify(payload));
  },
  getAccountsPermission: () => {
    return getItem(`${storagePrefix}account-permission`, 'json');
  },
  setXtenant: (tenant: string) => {
    setItem(`${storagePrefix}xtenant`, tenant);
  },
  getXtenant: () => {
    return getItem(`${storagePrefix}xtenant`);
  },
  setRegion: (region: string) => {
    setItem(`${storagePrefix}region`, region);
  },
  getRegion: () => {
    return getItem(`${storagePrefix}region`);
  },
  clearRegion: () => {
    return removeItem(`${storagePrefix}region`);
  },
  setMpToken: (token: string, marketPlaceType: string) => {
    setItem(`${storagePrefix}mp-token`, token);
    setItem(`${storagePrefix}mp-type`, marketPlaceType)
  },
  getMpToken: () => {
    return getItem(`${storagePrefix}mp-token`);
  },
  getMpType: () => {
    return getItem(`${storagePrefix}mp-type`)
  },
  setXAccount: (payload: string) => {
    setItem(`${storagePrefix}x-account`, payload)
  },
  getXAccount: () => {
    return getItem(`${storagePrefix}x-account`)
  },
  removeXAccount: () => {
    removeItem(`${storagePrefix}x-account`)
  },
  deleteMpToken: () => {
    removeItem(`${storagePrefix}mp-token`);
  },
  setXTenantIdList: () => {
    try {
      const tenantObj = storage.getMultiTenant();
      const tenantList = Object.values(tenantObj);
      if (tenantList?.length > 0) {
        setItem(`${storagePrefix}x-tenants`, tenantList?.flat()?.toString()?.replace('All,', ''));
      }
    } catch {
      console.log('Error creating x-tenant list');
    }
  },
  setXTenants: (tenantList: string) => {
    setItem(`${storagePrefix}x-tenants`, tenantList);
  },
  getXTenantIdList: () => {
    return getItem(`${storagePrefix}x-tenants`);
  },
  setTtk: (res: boolean) => {
    setItem(`${storagePrefix}show-ttk`, res);
  },
  getTtk: () => {
    return getItem(`${storagePrefix}show-ttk`, 'json');
  },
  setUpdatePermission: (payload: any) => {
    setItem(`${storagePrefix}update-permission`, JSON.stringify(payload));
  },
  gettUpdatePermission: () => {
    return getItem(`${storagePrefix}update-permission`, 'json');
  },
  removeTenentLocalData: () => {
    removeItem(`${storagePrefix}multi-tenant`)
    removeItem(`${storagePrefix}x-tenants`)
    removeItem(`${storagePrefix}xtenant`)
    removeItem(`${storagePrefix}tenant`)
  },
  setInitialAccount: (payload: any) => {
    setItem(`${storagePrefix}initial-account`, JSON.stringify(payload));
  },
  getInitialAccount: () => {
    return getItem(`${storagePrefix}initial-account`, 'json');
  },
  getJobsEducationBannerFirstShown: () => {
    const bannerFirstShown = getItem(`${storagePrefix}jobs-edu-banner-first-shown`);

    if (bannerFirstShown) return bannerFirstShown
    setItem(`${storagePrefix}jobs-edu-banner-first-shown`, Date.now().toString());
    return Date.now().toString()
  },
  getJobsEducationBannerClicked: () => {
    return getItem(`${storagePrefix}jobs-edu-banner-clicked`);
  },
  setJobsEducationBannerFirstShown: () => {
    const bannerFirstShownDate = getItem(`${storagePrefix}jobs-edu-banner-first-shown`);

    if (!bannerFirstShownDate) {
      setItem(`${storagePrefix}jobs-edu-banner-first-shown`, Date.now().toString());
    }
  },
  setJobsEducationBannerClicked: () => {
    setItem(`${storagePrefix}jobs-edu-banner-clicked`, true);
  },
};

export default storage;
