export const reusableStyles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centerAlignContent: {
    display: 'flex',
    alignItems: 'center'
  },
  spaceBtwnContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
