import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AlertComponenet from 'components/common/AlertComponents';
import { toast } from 'react-toastify';
import { JobsListyReq, JobsSharedSliceType, JobDetail, SummaryReq } from '../types';

const initialState: JobsSharedSliceType = {
  summary: {
    summaryData: null,
    status: 'ready'
  },
  showNotificationIndicator: false,
  jobsList: {
    scheduledInProgress: {
      scheduledInProgressData: null,
      status: 'ready'
    },
    completed: {
      completedData: null,
      status: 'ready'
    }
  },
  jobDetail: { jobStatus: undefined, jobsId: -1, name: '', onboardJobUpdateTrigger: false },
  tabValue: 0
};

const jobsSharedSlice = createSlice({
  name: 'jobsShared',
  initialState,
  reducers: {
    setJobsDetail: (state, action: PayloadAction<JobDetail>) => {
      state.jobDetail = action.payload;
    },
    setNotificationIndicator: (state, action: PayloadAction<boolean>) => {
      state.showNotificationIndicator = action.payload;
    },
    setTabValue: (state, action: PayloadAction<number>) => {
      state.tabValue = action.payload;
    },

    getSummaryInit: (state, action: PayloadAction<SummaryReq>) => {
      state.summary = { status: 'loading', summaryData: null };
    },
    getSummarySuccess: (state, action) => {
      state.summary = { status: 'success', summaryData: action?.payload };
    },
    getSummaryError: (state, action) => {
      state.summary = { status: 'error', summaryData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs-summary-error'
      });
    },
    getJLScheduleInprogressInit: (state, action: PayloadAction<JobsListyReq>) => {
      state.jobsList.scheduledInProgress = { status: 'loading', scheduledInProgressData: null };
    },
    getJLScheduleInprogressSuccess: (state, action) => {
      state.jobsList.scheduledInProgress = {
        status: 'success',
        scheduledInProgressData: action?.payload
      };
    },
    getJLScheduleInprogressError: (state, action) => {
      state.jobsList.scheduledInProgress = { status: 'error', scheduledInProgressData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs-inp-list-error'
      });
    },
    getJLCompletedInit: (state, action: PayloadAction<JobsListyReq>) => {
      state.jobsList.completed = { status: 'loading', completedData: null };
    },
    getJLCompletedSuccess: (state, action) => {
      state.jobsList.completed = {
        status: 'success',
        completedData: action?.payload
      };
    },
    getJLCompletedError: (state, action) => {
      state.jobsList.completed = { status: 'error', completedData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs--complet-list-error'
      });
    }
  }
});

export const {
  setJobsDetail,
  setTabValue,
  setNotificationIndicator,
  getJLScheduleInprogressInit,
  getJLScheduleInprogressSuccess,
  getJLScheduleInprogressError,
  getJLCompletedInit,
  getJLCompletedSuccess,
  getJLCompletedError,
  getSummaryInit,
  getSummarySuccess,
  getSummaryError
} = jobsSharedSlice.actions;

export default jobsSharedSlice.reducer;
