import { AppBar, Divider, Toolbar } from '@mui/material';
import { FC } from 'react';
// image
// local import
import { DRAWERWIDTH } from 'constants/pages';
import LeftMenu from './menu/leftMenu';
import RightMenu from './menu/rightMenu';

const drawerWidth = DRAWERWIDTH;

const TopNav: FC = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` }
      }}
      className="appbar">
      <Toolbar className="toolbar">
        <LeftMenu/>
        <RightMenu/>

      </Toolbar>
      <Divider sx={{ borderColor: 'rgba(225, 225, 225, 0.06)' }} />
    </AppBar>
  );
};

export default TopNav;
