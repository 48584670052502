import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { JobsInfoReq, JobsInfoRes } from '../types';

class Api {
  getJobsInfo = (payload: JobsInfoReq): Promise<ApiResponse<JobsInfoRes>> =>
    ApiService.get(`/api/v1/job/info/onboard`, {
      params: {
        ...payload
      }
    });
}

export const api = new Api();
