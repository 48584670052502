import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { SummaryReq, SummaryRes, JobsListyReq, JobsListyRes } from '../types';

class Api {
  getSummary = (payload: SummaryReq): Promise<ApiResponse<SummaryRes>> =>
    ApiService.post(`/api/v1/job/summary`, payload);

  getJobsList = (payload: JobsListyReq): Promise<ApiResponse<JobsListyRes[]>> =>
    ApiService.post(`/api/v1/job/list`, payload);
}

export const api = new Api();
