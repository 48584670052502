import { Box, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import format from 'date-fns/format';
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { epochToLocalDate } from 'utils/common';
import { PlannedScheduleTriggerResponse } from '../../../types';
import { ScheduleListType } from '../../../types/types';

const PlannedScheduleLabel: FC<ScheduleListType> = ({ handleScheduleLabel, scheduledInstanceIndex}) => {
  const { plannedOnboarding } = useAppSelector((state) => state.unmanagedVm);

  return (
    <List sx={{p:0, maxHeight: '562px', overflow: 'auto'}}>
      {plannedOnboarding?.plannedOnboardingData?.data?.bulkScheduleTriggerResponseList.map(
        (bulkList: PlannedScheduleTriggerResponse, index: number) => (
          <ListItemButton data-testid={format(epochToLocalDate(bulkList?.friendlyLabel?.value), "MMM dd yyyy 'at' p")} key={index} onClick={() => handleScheduleLabel(index)} sx={{
            backgroundColor: `${scheduledInstanceIndex === index ? 'rgba(255, 255, 255, 0.08)' : ''}`
          }}>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1">
                    {format(epochToLocalDate(bulkList?.friendlyLabel?.value), "MMM dd yyyy 'at' p")}
                  </Typography>
                  <Typography variant="body1" color="gray">
                    {bulkList?.totalInstanceCount} Instances &bull; {bulkList?.totalVolumeCount} Volumes
                </Typography>
                </Box>
              }
            />
          </ListItemButton>
        )
      )}
    </List>
  );
};

export default PlannedScheduleLabel;
