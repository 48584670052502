import { LoginCallback } from '@okta/okta-react';
import GlobalSpinner from 'components/GlobalSpinner';
import SignInCallback from 'pages/SignInCallback';
import { AzureCallback } from 'features/accounts/features/azure';
import SignIn from 'pages/Signin';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { msalConfigurationAccount } from 'features/auth/config/azureConfig';
import { MsalProvider } from '@azure/msal-react';
import { MarketPlaceContainer } from 'features/marketplace';
import { ListUsersContainer, AddUserContainer } from 'features/users';
import UnManagedVmContainer from 'features/scalar/components/unManagedVm/components/UnManagedVmContainer';
import {
  IntegrationContainer,
  InputSecretValuesContainer
} from 'features/audit/features/Integration';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GcpContainer } from 'features/accounts/features/gcp';
import config from 'config';
import { JobsContainer } from 'features/jobs';

const AuditDashboard = lazy(() => import('pages/AuditDashboard'));
const UploadReport = lazy(() => import('pages/UploadReport'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Scaler = lazy(() => import('pages/Scaler'));
const Onboard = lazy(() => import('pages/Onboard'));
const Kubernetes = lazy(() => import('pages/Kubernetes'));
const Accounts = lazy(() => import('features/accounts/features/accountHome'));
const AzureContainer = lazy(() => import('features/accounts/features/azure'));
const AwsContainer = lazy(() => import('features/accounts/features/aws'));
const DownloadInstrPdfContainer = lazy(() => import('features/accounts/features/downloadInstrPdf'));
const AccountLinkStatus = lazy(() => import('features/accounts/features/accountLinkStatus'));
const ManagePolicy = lazy(() => import('features/scalar/components/managePolicy'));

const MainRoute = () => {
  return (
    <Routes>
      {/* 
      protected route with layout additional control
       */}
      <Route element={<ProtectedRoute style={{ '&.MuiBox-root': { px: 0 } }} />}>
        <Route path="jobs" element={<JobsContainer />} />
      </Route>
      {/* 
      protected route with layout
       */}
      <Route element={<ProtectedRoute />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="scaler">
          <Route index element={<Scaler />} />
          <Route path="manage-policy" element={<ManagePolicy />} />
          <Route path="scale" element={<UnManagedVmContainer />} />
        </Route>
        <Route path="onboard" element={<Onboard />} />
        <Route path="kubernetes" element={<Kubernetes />} />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        <Route path="accounts">
          <Route
            index
            element={
              <MsalProvider instance={msalConfigurationAccount}>
                <Accounts />
              </MsalProvider>
            }
          />
          <Route
            path="azure"
            element={
              <MsalProvider instance={msalConfigurationAccount}>
                <AzureContainer />
              </MsalProvider>
            }
          />
        </Route>

        <Route
          path="accounts/gcp"
          element={
            <GoogleOAuthProvider clientId={config.gcpClientId}>
              <GcpContainer />
            </GoogleOAuthProvider>
          }
        />
        <Route path="accounts/download" element={<DownloadInstrPdfContainer />} />
        <Route path="accounts/status" element={<AccountLinkStatus />} />
        <Route path="accounts/aws" element={<AwsContainer />} />
        <Route path="users">
          <Route index element={<ListUsersContainer />} />
          <Route path="add" element={<AddUserContainer />} />
        </Route>
        <Route path="audit">
          <Route index element={<UploadReport />} />
          <Route path="dashboard" element={<AuditDashboard />} />
          <Route path="integration">
            <Route index element={<IntegrationContainer />} />
            <Route path="add" element={<InputSecretValuesContainer />} />
          </Route>
        </Route>
      </Route>

      {/* 
      protected route without layout
       */}

      {/* 
      public route
       */}
      <Route path="/signin/callback" element={<SignInCallback />} />
      <Route
        path="accounts/callback"
        element={
          <MsalProvider instance={msalConfigurationAccount}>
            <AzureCallback />
          </MsalProvider>
        }
      />
      <Route
        path="accounts/update/callback"
        element={
          <MsalProvider instance={msalConfigurationAccount}>
            <AzureCallback />
          </MsalProvider>
        }
      />

      <Route
        path="/login/callback"
        element={<LoginCallback loadingElement={<GlobalSpinner />} />}
      />
      <Route element={<PublicRoute />}>
        <Route path="signin" element={<SignIn />} />
        <Route path="aws-mp" element={<MarketPlaceContainer marketPlaceType="AWS" />} />
        <Route path="azure-mp" element={<MarketPlaceContainer marketPlaceType="AZURE" />} />
      </Route>
    </Routes>
  );
};

export default MainRoute;
